import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { RestClientService } from '../../needed/services/rest-client.service';
import { validarTodosCampos, mensajeError, mensajeInformacion } from '../../needed/util';
import { NgbTabset } from '@ng-bootstrap/ng-bootstrap';
import { USUARIO } from '../../needed/const';
import { Router } from '@angular/router';

@Component({
  selector: "app-emisores-form",
  templateUrl: "./emisores-form.component.html",
  styleUrls: ["./emisores-form.component.scss"],
})
export class EmisoresFormComponent implements OnInit {
  formRegistro: FormGroup;
  @ViewChild("adjuntarLogo") adjuntarLogo: ElementRef;
  @ViewChild("ajuntarCertificado")
  ajuntarCertificado: ElementRef;
  @ViewChild("tabSetMan") tabSetMan: NgbTabset;

  @Input() titulo: string = "Nuevo Emisor";
  @Output() regresar = new EventEmitter<any>();
  @Output() guardar = new EventEmitter<any>();
  @Output() iraP12 = new EventEmitter<any>();

  provincias: any[] = [];
  ciudades: any[] = [];
  ambientes: any[] = [];
  emisor: any;
  codigo: number = null;
  usuarioSess: any = { admin: false };
  logoData: File = null;
  oldLogo: any = null;
  oldLogoFn: any = null;

  codigoEmisor: number = null;
  codigoHashEmisor: string = "";
  // logoEmisor: string = "assets/img/modulos/imagen-icono.png";
  logoEmisor: string = null;
  cargaImagenNueva = false;
  tiposRimpe: any[] = [];
  agenteRetencionResolucionEditar = false;

  constructor(
    private fb: FormBuilder,
    private restClientService: RestClientService,
    private router: Router
  ) {
    this.inicializar();
    this.cargarDatos();
  }

  ngOnInit() {}

  private inicializar() {
    this.formRegistro = this.fb.group({
      ruc: ['', Validators.compose([Validators.required, Validators.minLength(13), Validators.maxLength(13)])],
      razonSocial: ['', Validators.compose([Validators.required, Validators.maxLength(70)])],
      nombreComercial: ['', Validators.required],
      provincia: [null, Validators.required],
      ciudad: [null, Validators.required],
      direccion: ['', Validators.required],
      telefono: ['', Validators.required],
      email: ['', Validators.compose([Validators.required, Validators.email])],
      llevaContabilidad: [false],
      exportador: [false],
      codigoCatalogoAmbiente: [null, Validators.required],
      codigoEmisor: [null],
      trimpe: ["", Validators.required],
      // resolucion: [""],
      agenteRetencion: [false],
      agenteRetencionResolucion: [""],
      // contribuyenteEspecial: [false]
    })
    this.restClientService.listar('provincia/listar', new Map<string, any>().set('activo', true)).subscribe(result => {
      this.provincias = result
    })
    this.emisor = null;
  }

  cargarDatos() {
    this.restClientService
      .listar("provincia/listar", new Map<string, any>().set("activo", true))
      .subscribe((result) => {
        this.provincias = result;
      });
    this.restClientService
      .listar("emisor/ambientes", new Map<string, any>())
      .subscribe((result) => {
        this.ambientes = result;
      });
    this.restClientService
    .consultar("regimen/listar", null)
    .subscribe((result: any) => {
      if (result) {
        this.tiposRimpe = result;
      }
    });
  }

  cargarInformacionEmisor() {
    let usuario = JSON.parse(localStorage.getItem(USUARIO));
    this.codigoHashEmisor = "";
    this.restClientService
      .consultar("usuarioEmisor/usuario", usuario.id)
      .subscribe((result) => {
        if (result != null) {
          this.codigoEmisor = result.codigoEmisor;
          this.restClientService
            .consultar("emisor", result.codigoEmisor)
            .subscribe((emisor) => {
              this.seleccionarProvincia(emisor.codigoProvincia);
              this.codigoHashEmisor = emisor.codigoEmisor;
              this.formRegistro.patchValue({
                ruc: emisor.ruc,
                razonSocial: emisor.razonSocial,
                nombreComercial: emisor.nombreComercial,
                provincia: emisor.codigoProvincia,
                ciudad: emisor.codigoCiudad,
                direccion: emisor.direccion,
                telefono: emisor.telefono,
                email: emisor.email,
                llevaContabilidad: emisor.llevaContabilidad,
                exportador: emisor.exportador,
                codigoCatalogoAmbiente: emisor.codigoCatalogoAmbiente,
                codigoEmisor: emisor.codigoEmisor,
                trimpe: emisor.trimpe,
                agenteRetencion: emisor.agenteRetencion,
                agenteRetencionResolucion: emisor.agenteRetencionResolucion,
                // contribuyenteEspecial: emisor.contribuyenteEspecial
              });
              this.emisor = emisor;

              this.restClientService
                .descargarArchivo("emisor/logo", result.codigoEmisor)
                .subscribe((resLogo) => {
                  if (resLogo != null && resLogo.size > 0) {
                    this.logoData = this.convertirBlobFile(
                      resLogo,
                      emisor.logofn
                    );
                    if (this.adjuntarLogo !== undefined) {
                      this.adjuntarLogo.nativeElement.innerText = this.logoData.name;
                    }
                    let reader = new FileReader();
                    reader.onload = (event: any) => {
                      this.logoEmisor = event.target.result;
                    };
                    reader.readAsDataURL(resLogo);
                  } else {
                    this.logoEmisor = "assets/img/modulos/imagen-icono.png";
                  }
                });
            });
        } else {
          this.logoEmisor = "assets/img/modulos/imagen-icono.png";
        }
      });

    this.formRegistro.patchValue({
      email: usuario.email,
    });
  }

  convertirBlobFile(blob: Blob, fileName: string) {
    let b: any = blob;
    b.lastModifiedDate = new Date();
    b.name = fileName;
    return <File>blob;
  }

  @Input()
  public set objeto(obj: any) {
    this.oldLogo = null;
    this.oldLogoFn = null;
    this.emisor = obj;
    if (obj == null) {
      this.codigoHashEmisor = "";
    } else {
      this.seleccionarProvincia(obj.codigoProvincia);
      this.formRegistro.patchValue({
        ruc: obj.ruc,
        razonSocial: obj.razonSocial,
        nombreComercial: obj.nombreComercial,
        provincia: obj.codigoProvincia,
        ciudad: obj.codigoCiudad,
        direccion: obj.direccion,
        telefono: obj.telefono,
        email: obj.email,
        llevaContabilidad: obj.llevaContabilidad,
        exportador: obj.exportador,
        codigoCatalogoAmbiente: obj.codigoCatalogoAmbiente,
        codigoEmisor: obj.codigoEmisor,
        trimpe: obj.trimpe,
        agenteRetencion: obj.agenteRetencion,
        agenteRetencionResolucion: obj.agenteRetencionResolucion,
        // contribuyenteEspecial: emisor.contribuyenteEspecial
        // resolucion: obj.resolucion
      });
      this.codigoHashEmisor = obj.codigoEmisor;
      this.oldLogo = obj.logo;
      this.oldLogoFn = obj.logofn;

      this.restClientService
        .descargarArchivo("emisor/logo", obj.codigo)
        .subscribe((resLogo) => {
          if (resLogo != null && resLogo.size > 0) {
            this.logoData = this.convertirBlobFile(resLogo, obj.logofn);
            if (this.adjuntarLogo !== undefined) {
              this.adjuntarLogo.nativeElement.innerText = this.logoData.name;
            }
            let reader = new FileReader();
            reader.onload = (event: any) => {
              this.logoEmisor = event.target.result;
            };
            reader.readAsDataURL(resLogo);
          } else {
            this.logoEmisor = "assets/img/modulos/imagen-icono.png";
          }
        }
      );

      this.codigo = obj.codigo;
      this.titulo = "Actualizar Cliente";
    }
  }

  @Input()
  public set usuarioSesion(usss: any) {
    this.usuarioSess = usss;
  }

  seleccionarProvincia(prov: any) {
    if (prov != null) {
      this.restClientService
        .listar(
          "ciudad/listar",
          new Map<string, any>().set("codigoProvincia", prov)
        )
        .subscribe((result) => {
          this.ciudades = result;
        });
    } else {
      this.formRegistro.patchValue({ ciudad: null });
      this.ciudades = [];
    }
  }

  onRegresar() {
    this.regresar.emit(new FormData());
  }

  onGuardar() {
    validarTodosCampos(this.formRegistro);
    if (!this.formRegistro.valid) {
      mensajeError(
        "Validar formulario",
        "Se deben validar los campos obligatorios en el formulario"
      );
    } else {
      const formData: FormData = new FormData();
      formData.append('ruc', this.formRegistro.value.ruc);
      formData.append('razonSocial', this.formRegistro.value.razonSocial);
      formData.append('nombreComercial', this.formRegistro.value.nombreComercial);
      formData.append('codigoProvincia', this.formRegistro.value.provincia);
      formData.append('codigoCiudad', this.formRegistro.value.ciudad);
      formData.append('direccion', this.formRegistro.value.direccion);
      formData.append('telefono', this.formRegistro.value.telefono);
      formData.append('email', this.formRegistro.value.email);
      formData.append('exportador', this.formRegistro.value.exportador);
      formData.append('llevaContabilidad', this.formRegistro.value.llevaContabilidad);
      formData.append('codigoCatalogoAmbiente', this.formRegistro.value.codigoCatalogoAmbiente);
      formData.append("trimpe", this.formRegistro.value.trimpe);
      formData.append("agenteRetencion", this.formRegistro.value.agenteRetencion == null? false: this.formRegistro.value.agenteRetencion);
      formData.append("agenteRetencionResolucion", this.formRegistro.value.agenteRetencionResolucion == null? '': this.formRegistro.value.agenteRetencionResolucion);
      if (this.logoData != null && this.logoData.name != null) {
        formData.append("logoInfo", this.logoData.name);
        formData.append("logoData", this.logoData, this.logoData.name);
      } else {
        formData.append("logo", this.oldLogo == null ? "" : this.oldLogo);
        formData.append("logofn", this.oldLogoFn == null ? "" : this.oldLogoFn);
      }

      if (this.codigo != null) {
        this.restClientService
          .actualizarMultipart(
            "emisor",
            this.codigo,
            formData
          )
          .subscribe((result) => {
            mensajeInformacion(
              null,
              "Emisor Actualizado satisfactoriamente"
            ).then((res) => {
              this.emisor = true;
              // this.guardar.emit(result);
              this.iraP12.emit(result);
            });
          });
      } else {
        this.restClientService
          .guardarMultipart(
            "emisor",
            formData
          )
          .subscribe((result) => {
            this.codigo = result.codigo;
            // this.crearRelacion(this.codigo);
            mensajeInformacion(
              null,
              "Emisor Registrado satisfactoriamente"
            ).then((res) => {
              this.emisor = true;
              // this.guardar.emit(result);
              this.iraP12.emit(result);
            });
          }
        );
      }
    }
  }

  handleFileInput(evt) {
    this.cargaImagenNueva = true;
    let files = evt.target.files;
    let fileItem = files.item(0);
    this.logoData = fileItem;
    let reader = new FileReader();

    reader.onload = (event: any) => {
      this.logoEmisor = event.target.result;
    };
    if (fileItem != null) {
      reader.readAsDataURL(fileItem);
      if (this.adjuntarLogo !== undefined) {
        this.adjuntarLogo.nativeElement.innerText = fileItem.name;
      }
    } else {
      this.logoEmisor = "";
      if (this.adjuntarLogo !== undefined) {
        this.adjuntarLogo.nativeElement.innerText = "";
      }
    }
  }

  onInico() {
    this.router.navigate(["/dashboard"]);
  }

  agenteRetencionChange() {
    // console.log(this.formRegistro.value.agenteRetencion);
    this.agenteRetencionResolucionEditar = this.formRegistro.value.agenteRetencion;
    this.formRegistro.patchValue({
      agenteRetencionResolucion: ""
    });
  }
}
