import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { LOGIN, USUARIO } from '../const';
import { RestClientService } from '../services/rest-client.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(private router: Router, private restClientService: RestClientService) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    if (localStorage.getItem(LOGIN)) {
      let usuario = JSON.parse(localStorage.getItem(USUARIO));
      if (usuario.resetearClave) {
        this.router.navigate(['/resetearclave']);
        return false;
      // } else if (usuario.admin) {
      //   return true;
      // } else if (usuario.idPrincipal != null) {
      //   return true;
      // } else {
      //   this.restClientService.consultar('usuarioEmisor/usuario', usuario.id).subscribe(result => {
      //     if (result != null) {
      //       if (result.estadoCatalogo == 'EUSVAL') {
      //         return true;
      //       } else {
      //         this.router.navigate(['/usuarioemisor']);
      //         return false;
      //       }
      //     } else {
      //       this.router.navigate(['/usuarioemisor']);
      //       return false;
      //     }
      //   });
      //   return true;
      }
      return true;
    } else {
      this.router.navigate(['/login']);
      return false;
    }

  }
}
