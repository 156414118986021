import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-visor-documento',
  templateUrl: './visor-documento.component.html',
  styleUrls: ['./visor-documento.component.scss']
})
export class VisorDocumentoComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
