import { Component } from "@angular/core";
import { FormGroup, FormBuilder, FormControl, Validators } from "@angular/forms";
import { PasswordValidation } from "../../needed/interceptor/password-validation";
import {
  validarTodosCampos,
  mensajeError,
  mensajeInformacion,
  mensaje,
} from "../../needed/util";
import { RestClientService } from "../../needed/services/rest-client.service";
import { Router } from "@angular/router";

@Component({
  selector: "app-dashboard",
  templateUrl: "register.component.html",
})
export class RegisterComponent {
  formRegistro: FormGroup;
  invalid = true;
  constructor(
    private fb: FormBuilder,
    private restClientService: RestClientService,
    private router: Router
  ) {
    this.inicializar();
  }

  private inicializar() {
    this.formRegistro = this.fb.group(
      {
        username: ["", null],
        email: ["", null],
        password: ["", null],
        repassword: ["", null],
        nombre: ["", null],
        identificacion: ["", null],
      }
    );
  }

  irInicioSesion() {
    this.router.navigate(['/login']);
  }

  validar(event: any) {
    let invalid: boolean = false;
    if (!this.isUsername(this.formRegistro.value.username)) {
      this.formRegistro.controls.username.setErrors({ incorrect: true });
      invalid = true;
    } else {
      this.formRegistro.controls.username.setErrors(null);
    }

    if (!this.isNombre(this.formRegistro.value.nombre)) {
      this.formRegistro.controls.nombre.setErrors({ incorrect: true });
      invalid = true;
    } else {
      this.formRegistro.controls.nombre.setErrors(null);
    }
    if (!this.isEmail(this.formRegistro.value.email)) {
      this.formRegistro.controls.email.setErrors({ incorrect: true });
      invalid = true;
    } else {
      this.formRegistro.controls.email.setErrors(null);
    }
    if (!this.isContrasena(this.formRegistro.value.password)) {
      this.formRegistro.controls.password.setErrors({ incorrect: true });
      invalid = true;
    } else {
      this.formRegistro.controls.password.setErrors(null);
    }
    if (!this.isRecontrasena(this.formRegistro.value.password, this.formRegistro.value.repassword)) {
      this.formRegistro.controls.repassword.setErrors({ incorrect: true });
      invalid = true;
    } else {
      this.formRegistro.controls.repassword.setErrors(null);
    }
    this.invalid = invalid;
  }

  registrar() {
    if (this.invalid) {
      mensajeError(
        "Validar formulario",
        "Se deben validar los campos obligatorios en el formulario"
      );
      return;
    }
    const body = {
      username: this.formRegistro.value.username,
      nombre: this.formRegistro.value.nombre,
      password: this.formRegistro.value.password,
      email: this.formRegistro.value.email,
    };
    this.restClientService
      .guardar("auth/registrar", body)
      .subscribe((result) => {
        result = result[0];
        mensajeInformacion(
          null,
          (result == "OK" ? "El usuario se ha registrado satisfactoriamente" : result)
        ).then((res) => {
          if (result == "OK") {
            this.router.navigate(["/login"]);
          }
        });
      });
  }

  isUsername(valor: string) {
    try {
      return /^([a-zA-Z\-0-9\_]{4,})$/.test(valor);
    } catch (err) {
      return false;
    }
  }

  isNombre(valor: string) {
    try {
      return valor.trim().length > 0;
    } catch (err) {
      return false;
    }
  }

  isContrasena(valor: string) {
    try {
      return valor.trim().length >= 6;
    } catch (err) {
      return false;
    }
  }

  isRecontrasena(valor: string, valor2: string) {
    try {
      // console.log( valor2.trim().length >= 6 && valor2 == valor );
      return valor2.trim().length >= 6 && valor2 == valor;
    } catch (err) {
      return false;
    }
  }

  isEmail(valor: string): boolean {
    try {
      const re = new RegExp(
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
      return re.test(valor);
    } catch (err) {
      return false;
    }
  }
}
