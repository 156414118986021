import { Injectable } from '@angular/core';
import {
    HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpHeaders
} from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';
import { LOGIN } from '../const';


@Injectable()
export class RestInterceptor implements HttpInterceptor {

    constructor() { }

    esApiPublica(headers: HttpHeaders) {
        if (headers != null && headers.get('X-Api-Public') != null) {
            return true;
        }
        return false;
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const token = localStorage.getItem(LOGIN);
        let authReq: HttpRequest<any>;
        if (this.esApiPublica(req.headers)
          || (req.url.endsWith('/portal/documentos')
              || req.url.endsWith('/auth/login')
              || req.url.endsWith('/auth/registrar')
              || req.url.endsWith('/auth/recuperar/usuario')
              || req.url.endsWith('/auth/recuperar/contrasena')
        )) {
            authReq = req.clone({});
        } else {
            authReq = req.clone({ headers: req.headers.set('Authorization', 'Bearer ' + token) })
        }

        return next.handle(authReq).pipe(
            catchError(error => {
                return throwError(error);
            }),
            finalize(() => {
            })
        );
    }
}
