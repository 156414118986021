import { Component } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { PasswordValidation } from '../../needed/interceptor/password-validation';
import { validarTodosCampos, mensajeError, mensajeInformacion } from '../../needed/util';
import { RestClientService } from '../../needed/services/rest-client.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-dashboard',
  templateUrl: 'recuperar.component.html'
})
export class RecuperarComponent {

  formRegistro: FormGroup;
  enviado: boolean = false;
  dataEmail: string = '';
  invalid: boolean = true;
  labelSending: string = 'Enviar';

  constructor(private fb: FormBuilder, private restClientService: RestClientService,
    private router: Router) {
    this.inicializar();
  }

  private inicializar() {
    this.formRegistro = this.fb.group({
      email: ['', null]
      // email: ['', Validators.compose([Validators.required, Validators.email])]
    });
  }

  enviar() {
    this.labelSending = "Enviando...";
    if (!this.formRegistro.valid) {
      mensajeError('Validar formulario', 'Se deben validar los campos obligatorios en el formulario');
    } else {
      const body = {
        email: this.formRegistro.value.email
      };

      this.restClientService.guardar('auth/recuperar/contrasena', body).subscribe(result => {
        if (result != null) {
          this.enviado = true;
          this.dataEmail = result.email;
          this.formRegistro.disable();
        } else {
          mensajeError(
            'No se encontro usuario asociado',
            this.formRegistro.value.email + ' no fue encontrado en el sistema'
          );
          this.labelSending = "Enviar"; 
        }
      });
    }
  }

  irInicioSesion() {
    this.router.navigate(['/login']);
  }

  validar(event: any) {
    this.invalid = false;
    if (!this.isEmail(this.formRegistro.value.email)) {
      this.formRegistro.controls.email.setErrors({ incorrect: true });
      this.invalid = true;
    } else {
      this.formRegistro.controls.email.setErrors(null);
    }
  }

  isEmail(valor: string): boolean {
    try {
      const re = new RegExp(
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
      return re.test(valor);
    } catch (err) {
      return false;
    }
  }
}
