import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { URL_SERVICE, LOGIN, USUARIO, MENU } from '../const';
import { HttpHeaders, HttpResponse, HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { RestClientService } from './rest-client.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private http: HttpClient, private restClientService: RestClientService) { }

  public login(body: any): Observable<boolean> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json').set('Access-Control-Allow-Origin','*');
    return this.http.post(URL_SERVICE + 'auth/login', JSON.stringify(body), { observe: 'response', headers: headers })
      .pipe(map((resp: HttpResponse<any>) => {
        const token = resp.body['X-Token'];
        if (token) {
          localStorage.setItem(LOGIN, token);
          localStorage.setItem(USUARIO, JSON.stringify(resp.body));
          return true;
        } else {
          return false;
        }
      }));
  }

  public logout(): void {
    localStorage.removeItem(LOGIN);
    localStorage.removeItem(USUARIO);
    localStorage.removeItem(MENU);
  }
}
