export const LOGIN = 'LOGIN';
export const USUARIO = 'USUARIO';
export const MENU = 'MENU';
export const URL_SERVICE = 'https://app.facturero.ec/facturero/';
//export const URL_SERVICE = 'http://localhost:8080/facturero/';

export const MESES =  [
    {nombre: 'Enero', valor: 1},
    {nombre: 'Febrero', valor: 2},
    {nombre: 'Marzo', valor: 3},
    {nombre: 'Abril', valor: 4},
    {nombre: 'Mayo', valor: 5},
    {nombre: 'Junio', valor: 6},
    {nombre: 'Julio', valor: 7},
    {nombre: 'Agosto', valor: 8},
    {nombre: 'Septiembre', valor: 9},
    {nombre: 'Octubre', valor: 10},
    {nombre: 'Noviembre', valor: 11},
    {nombre: 'Diciembre', valor: 12}
];
