import { Component, OnInit, ViewChild, ElementRef, Input, Output, EventEmitter } from "@angular/core";
import { USUARIO } from "../../needed/const";
import { RestClientService } from "../../needed/services/rest-client.service";
import { Router } from "@angular/router";
import { AuthService } from "../../needed/services/auth.service";

@Component({
  selector: 'app-emisor-certificado',
  templateUrl: './emisor-certificado.component.html',
  styleUrls: ['./emisor-certificado.component.scss']
})
export class EmisorCertificadoComponent implements OnInit {
  objEmisor: any;
  enEmisor: boolean = true;
  enP12: boolean = true;

  usuarioSesion: any = {
    admin: false,
  };

  constructor(
    private restClientService: RestClientService,
    private router: Router,
    private authService: AuthService
  ) {
    this.cargarInformacionEmisor();
  }

  ngOnInit() {}

  cargarInformacionEmisor() {
    let usuario = JSON.parse(localStorage.getItem(USUARIO));
    this.restClientService
      .consultar("usuarioEmisor/usuario", usuario.id)
      .subscribe((result) => {
        if (result != null) {
          this.restClientService
            .consultar("emisor", result.codigoEmisor)
            .subscribe((emisor) => {
              this.objEmisor = emisor;
            });
        }
      });
  }

  beforeChange(evento) {
  }

  onRegresarP12(data: any) {
    this.router.navigate(["/dashboard"]);
  }

  onGuardarP12(data: any) {
    this.router.navigate(["/dashboard"]);
  }
}
