import { Component } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { validarTodosCampos, mensajeError } from '../../needed/util';
import { Router } from '@angular/router';
import { AuthService } from '../../needed/services/auth.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: 'login.component.html'
})
export class LoginComponent {

  formLogin: FormGroup;
  verRegistrar:boolean = true;

  constructor(private fb: FormBuilder, private authService: AuthService,
    private router: Router) {
    this.inicializar();
  }

  private inicializar() {
    this.formLogin = this.fb.group({
      username: ['', Validators.required],
      password: ['', Validators.required]
    })
  }

  iniciar(){
    validarTodosCampos(this.formLogin);
    if(this.formLogin.valid){
      let body = {
        username: this.formLogin.value.username,
        password: this.formLogin.value.password
      }
      this.authService.login(body).subscribe((result) => {
        if (result === true) {
          this.router.navigate(['/']);
        }
      }, (error) => {
        mensajeError('No se pudo iniciar sesion', 'Verificar username y password para el correcto inicio de sesion');
      });
    }
  }

  irRegistrar(){
    // this.router.navigate(['/register']);
  }

  irRecuperar() {
    this.router.navigate(['/recuperar-contrasena']);
  }

  irRecuperarUsuario(){
    this.router.navigate(['/recuperar-usuario']);
  }

  irRecuperarContrasena(){
    this.router.navigate(['/recuperar-contrasena']);
  }
}
