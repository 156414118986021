import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { Router } from "@angular/router";
import { RestClientService } from "../../needed/services/rest-client.service";
import { mensajeError, validarTodosCampos } from "../../needed/util";

@Component({
  selector: "app-recuperar-usuario",
  templateUrl: "./recuperar-usuario.component.html",
  styleUrls: ["./recuperar-usuario.component.scss"],
})
export class RecuperarUsuarioComponent implements OnInit {
  ngOnInit() {}

  formRegistro: FormGroup;
  enviado: boolean = false;
  dataEmail: string = "";
  invalid: boolean = true;

  constructor(
    private fb: FormBuilder,
    private restClientService: RestClientService,
    private router: Router
  ) {
    this.inicializar();
  }

  private inicializar() {
    this.formRegistro = this.fb.group({
      email: ["", null],
    });
  }

  enviar() {
    validarTodosCampos(this.formRegistro);
    if (!this.formRegistro.valid) {
      mensajeError(
        "Validar formulario",
        "Se deben validar los campos obligatorios en el formulario"
      );
    } else {
      const body = {
        email: this.formRegistro.value.email,
      };

      this.restClientService
        .guardar("auth/recuperar/usuario", body)
        .subscribe((result) => {
          this.enviado = true;
          this.dataEmail = (result != null? result.email: this.formRegistro.value.email);
          this.formRegistro.disable();
        });
    }
  }

  irInicioSesion() {
    this.router.navigate(["/login"]);
  }

  validar(event: any) {
    this.invalid = false;
    if (!this.isEmail(this.formRegistro.value.email)) {
      this.formRegistro.controls.email.setErrors({ incorrect: true });
      this.invalid = true;
    } else {
      this.formRegistro.controls.email.setErrors(null);
    }
  }

  isEmail(valor: string): boolean {
    try {
      const re = new RegExp(
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
      return re.test(valor);
    } catch (err) {
      return false;
    }
  }
}
