import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// Import Containers
import { DefaultLayoutComponent } from './containers';

import { P404Component } from './views/error/404.component';
import { P500Component } from './views/error/500.component';
import { LoginComponent } from './views/login/login.component';
import { AuthGuard } from './needed/guard/auth.guard';
import { UsuarioemisorComponent } from './views/usuarioemisor/usuarioemisor.component';
import { RecuperarComponent } from './views/recuperar/recuperar.component';
import { RegisterComponent } from './views/register/register.component';
import { ResetearclaveComponent } from './config/resetearclave/resetearclave.component';
import { UsuariocertificadoComponent } from './views/usuariocertificado/usuariocertificado.component';
import { SolicitarCertificadoComponent } from './config/solicitar-certificado/solicitar-certificado.component';
import { RecuperarUsuarioComponent } from './views/recuperar-usuario/recuperar-usuario.component';

export const routes: Routes = [
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full',
  },
  {
    path: '404',
    component: P404Component,
    data: {
      title: 'Page 404'
    }
  },
  {
    path: '500',
    component: P500Component,
    data: {
      title: 'Page 500'
    }
  },
  {
    path: 'login',
    component: LoginComponent,
    data: {
      title: 'Login Page'
    }
  },
  {
    path: 'recuperar-usuario',
    component: RecuperarUsuarioComponent,
    data: {
      title: 'Recuperar Usuario'
    }
  },
  {
    path: 'recuperar-contrasena',
    component: RecuperarComponent,
    data: {
      title: 'Recuperar Contraseña'
    }
  },
  // {
  //   path: 'register',
  //   component: RegisterComponent,
  //   data: {
  //     title: 'Register Page'
  //   }
  // },
  {
    path: 'usuarioemisor',
    component: UsuarioemisorComponent,
    data: {
      title: 'Usuario Emisor'
    }
  },
  {
    path: 'usuariocertificado',
    component: UsuariocertificadoComponent,
    data: {
      title: 'Usuario Certificado del Emisor'
    }
  },
  {
    path: 'resetearclave',
    component: ResetearclaveComponent,
    data: {
      title: 'Resetear la clave del Usuario'
    }
  },
  {
    path: 'portal',
    children: [
      {
        path: 'admin',
        loadChildren: () => import('./views/portal/portal.module').then(m => m.PortalModule)
      }
    ]
  },
  {
    path: '',
    canActivate: [AuthGuard],
    component: DefaultLayoutComponent,
    data: {
      title: 'Inicio'
    },
    children: [
      {
        path: 'sga',
        loadChildren: () => import('./views/sga/sga.module').then(m => m.SgaModule)
      },
      {
        path: 'gts',
        loadChildren: () => import('./views/gts/gts.module').then(m => m.GtsModule)
      },
      {
        path: 'imp',
        loadChildren: () => import('./views/imp/imp.module').then(m => m.ImpModule)
      },
      {
        path: 'reporte',
        loadChildren: () => import('./views/reporte/reporte.module').then(m => m.ReporteModule)
      },
      {
        path: 'dashboard',
        loadChildren: () => import('./views/dashboard/dashboard.module').then(m => m.DashboardModule)
      },
      {
        path: 'solicitarcertificado',
        component: SolicitarCertificadoComponent,
        data: {
          title: 'Solicitar certificado'
        }
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
