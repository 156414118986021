import { Component, OnInit, ViewChild, ElementRef, Input, Output, EventEmitter } from "@angular/core";
import { USUARIO } from "../../needed/const";
import { RestClientService } from "../../needed/services/rest-client.service";
import { Router } from "@angular/router";
import { AuthService } from "../../needed/services/auth.service";
import { NgbTabset } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "app-emisor",
  templateUrl: "./emisor.component.html",
  styleUrls: ["./emisor.component.scss"],
})
export class EmisorComponent implements OnInit {
  @Input() botonCerrar: boolean;
  @Input() iraCertificado: boolean;

  objEmisor: any;
  idActivo: string = "config";

  usuarioSesion: any = {
    admin: false,
  };

  constructor(
    private restClientService: RestClientService,
    private router: Router,
    private authService: AuthService
  ) {
    this.cargarInformacionEmisor();
  }

  ngOnInit() {
    this.idActivo = this.iraCertificado? "certificado": "config";
  }

  cargarInformacionEmisor() {
    let usuario = JSON.parse(localStorage.getItem(USUARIO));
    this.restClientService
      .consultar("usuarioEmisor/usuario", usuario.id)
      .subscribe((result) => {
        if (result != null) {
          this.restClientService
            .consultar("emisor", result.codigoEmisor)
            .subscribe((emisor) => {
              this.objEmisor = emisor;
            });
        }
      });
  }

  beforeChange(evento) {}

  cerrarSesion() {
    this.authService.logout();
    this.router.navigate(["/login"]);
  }

  onRegresarEmisor(data: any) {}

  onGuardarEmisor(data: any) {
    this.objEmisor = data;
    this.idActivo = "certificado";
  }

  onRegresarP12(data: any) {
    this.objEmisor = data;
  }

  onGuardarP12(data: any) {
    this.router.navigate(["/dashboard"]);
  }
}
