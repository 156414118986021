import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent implements OnInit {

  @ViewChild('compModal') compModal: TemplateRef<any>;

  modalRef: BsModalRef;

  constructor(private modalService: BsModalService) { }

  ngOnInit() {
  }

  public showAction() {
    let config: ModalOptions = {
      backdrop: 'static',
      class: 'modal-dialog-centered',
      animated: true
    }
    this.modalRef = this.modalService.show(this.compModal, config);
  }

  public hideAction() {
    this.modalRef.hide()
  }

}
