import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { USUARIO } from "../../needed/const";
import { RestClientService } from "../../needed/services/rest-client.service";
import { mensajeError, mensajeInformacion } from "../../needed/util";

@Component({
  selector: "app-solicitar-certificado",
  templateUrl: "./solicitar-certificado.component.html",
  styleUrls: ["./solicitar-certificado.component.scss"],
})
export class SolicitarCertificadoComponent implements OnInit {
  formulario: FormGroup;
  cedula: File = null;
  ruc: File = null;
  carta: File = null;
  isGuardar: boolean = false;
  codigo: number = null;
  estado: number = null;
  estadoSigla: string = "";
  isDisabled: boolean = true;

  constructor(
    private fb: FormBuilder,
    private restClientService: RestClientService,
    private router: Router
  ) {}

  ngOnInit() {
    this.formulario = this.fb.group({
      telefono: [
        "",
        Validators.compose([Validators.required, Validators.maxLength(16)]),
      ],
    });
    this.restClientService
      .consultar("solicitudfirma/estado", null)
      .subscribe((result) => {
        if (result != null) {
          this.estado = result.codigo;
          this.estadoSigla = result.sigla;
          // this.estadoSigla = 'ESFSOL';
          // this.estadoSigla = 'ESFEP';
          // this.estadoSigla = 'ESFCRE';
          // this.estadoSigla = 'ESFENT';
          // this.estadoSigla = "ESFINC";
          // this.estadoSigla = '';
          if (this.estadoSigla == "ESFINC") {
            this.cargarTelefonoDeFirma();
            this.isDisabled = false;
            // this.isGuardar = true;
          }
        } else {
          this.cargarTelefonoDeUsuario();
          this.isDisabled = false;
        }
      });
  }

  cargarTelefonoDeFirma() {
    this.restClientService
      .consultar("solicitudfirma/por/usuario", null)
      .subscribe((result) => {
        this.formulario.patchValue({
          telefono: result.celular,
        });
      });
  }

  cargarTelefonoDeUsuario() {
    let usuario: any = JSON.parse(localStorage.getItem(USUARIO));
    this.restClientService
      .consultar("emisor/por/usuario", usuario.id)
      .subscribe((result) => {
        this.formulario.patchValue({
          telefono: result.telefono,
        });
      });
  }

  onGuardar() {
    if (!this.isGuardar) {
      mensajeError(
        "Validar formulario",
        "Se deben validar los campos obligatorios en el formulario"
      );
    } else {
      const formData: FormData = new FormData();
      formData.append("certificadoInfo", this.cedula.name);
      formData.append("certificadoData", this.cedula, this.cedula.name);
      formData.append("rucInfo", this.ruc.name);
      formData.append("rucData", this.ruc, this.ruc.name);
      formData.append("cartaInfo", this.carta.name);
      formData.append("cartaData", this.carta, this.carta.name);
      formData.append("telefono", this.formulario.value.telefono);

      if (this.codigo != null) {
        this.restClientService
          .actualizarMultipart("solicitudfirma", this.codigo, formData)
          .subscribe((result) => {
            mensajeInformacion(
              "Solicitud de Firma Actualizado",
              "La solicitud se ha actualizado satisfactoriamente"
            ).then((res) => {
              this.onInico();
            });
          });
      } else {
        this.restClientService
          .guardarMultipart("solicitudfirma", formData)
          .subscribe((result) => {
            // this.codigo = result.codigo;
            mensajeInformacion(
              "Solicitud de Firma Registrada",
              "La solicitud se ha registrado satisfactoriamente"
            ).then((res) => {
              this.onInico();
            });
          });
      }
    }
  }

  isMal(fuente: any): boolean {
    return this.isDisabled
      ? true
      : fuente != null && fuente.name.slice(-4).toLowerCase() == ".pdf";
  }

  handleFile(evt: any, opcion: number): void {
    let files: any;
    let fileItem: any;
    if (evt == null) {
      switch (opcion) {
        case 1:
          fileItem = this.cedula;
          break;
        case 2:
          fileItem = this.ruc;
          break;
        case 3:
          fileItem = this.carta;
          break;
      }
    } else {
      files = evt.target.files;
      fileItem = files.item(0);
    }
    switch (opcion) {
      case 1:
        this.cedula = fileItem;
        break;
      case 2:
        this.ruc = fileItem;
        break;
      case 3:
        this.carta = fileItem;
        break;
    }
    if (!this.isMal(fileItem)) {
      mensajeError(
        "Validar formato",
        "El archivo a cargar tiene que ser en formato PDF"
      );
    }
    this.validar(null);
  }

  validar(event: any) {
    this.isGuardar =
      this.formulario.value.telefono != null &&
      this.formulario.value.telefono != "" &&
      this.isMal(this.cedula) &&
      this.isMal(this.ruc) &&
      this.isMal(this.carta);
  }

  onInico() {
    this.router.navigate(["/dashboard"]);
  }

  onDescargar() {}
}
