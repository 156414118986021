import Swal from 'sweetalert2';
import { FormGroup, FormControl } from '@angular/forms';

export function validarTodosCampos(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
        const control = formGroup.get(field);
        if (control instanceof FormControl) {
            control.markAsTouched({ onlySelf: true });
        } else if (control instanceof FormGroup) {
            this.validarTodosCampos(control);
        }
    });
}

export function mensaje(mensaje: string): Promise<any> {
    return Swal.fire({
        title: null,
        html: mensaje,
        type: null,
        confirmButtonText: 'Aceptar',
        confirmButtonClass: 'btn--main',
        buttonsStyling: false
    })
}

export function mensajeInformacion(titulo: string, mensaje: string): Promise<any> {
    return Swal.fire({
        title: titulo,
        html: mensaje,
        type: 'info',
        confirmButtonText: 'Aceptar',
        confirmButtonClass: 'btn--main',
        buttonsStyling: false
    })
}

export function mensajeError(titulo: string, mensaje: string): Promise<any> {
    return Swal.fire({
        title: titulo,
        html: mensaje,
        type: 'error',
        confirmButtonText: 'Aceptar',
        confirmButtonClass: 'btn--main',
        buttonsStyling: false
    })
}

export function mensajeConfirmacion(titulo: string, mensaje: string, textoConfirmacion: string = 'Sí, deseo eliminar el registro'): Promise<any> {
    return Swal.fire({
        title: titulo,
        html: mensaje,
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: textoConfirmacion,
        cancelButtonText: 'No',
        confirmButtonClass: 'btn--main',
        cancelButtonClass: 'btn--danger sweet-alert-cancel',
        buttonsStyling: false
    });
}

export function mensajeConfirmar(
    titulo: string,
    mensaje: string
): Promise<any> {
    return Swal.fire({
        title: titulo,
        html: mensaje,
        type: "warning",
        showCancelButton: true,
        confirmButtonText: "Si",
        cancelButtonText: "No",
        confirmButtonClass: "btn--main",
        cancelButtonClass: "btn--danger sweet-alert-cancel",
        buttonsStyling: false,
    });
}

export function mensajeConfirmacionExtra(titulo: string, mensaje: string, botonConf: string): Promise<any> {
    return Swal.fire({
        title: titulo,
        html: mensaje,
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Si, ' + botonConf,
        cancelButtonText: 'No',
        confirmButtonClass: 'btn--main',
        cancelButtonClass: 'btn--danger sweet-alert-cancel',
        buttonsStyling: false
    });
}


