import { Component, OnInit, Input, ViewChild, ElementRef, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators, AbstractControl } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { RestClientService } from '../../needed/services/rest-client.service';
import { mensajeError, mensajeInformacion, validarTodosCampos } from '../../needed/util';


@Component({
  selector: 'app-perfil',
  templateUrl: './perfil.component.html',
  styleUrls: ['./perfil.component.css']
})
export class PerfilComponent implements OnInit {

  @ViewChild("adjuntar", { read: ElementRef }) tref: ElementRef;
  usuario: string;
  public formUsuario: FormGroup;
  fileToUpload: File = null;
  imagen: any = "assets/img/modulos/imagen-icono.png";
  conservarImagen: boolean = false;

  email: string = ''

  @Output() postGuardar = new EventEmitter<any>();

  constructor(private fb: FormBuilder,
    private restClient: RestClientService,
    private domSanitizer: DomSanitizer) {
    this.inicializar()
  }

  ngOnInit() {
  }
  private inicializar() {
    this.imagen = 'assets/img/modulos/imagen-icono.png'
    this.formUsuario = this.fb.group({
      identificacion: [null, Validators.required],
      nombre: ['', Validators.required]
    });
  }

  @Input()
  public set username(usuario: string) {
    this.usuario = usuario;
    if (usuario != null) {
      this.restClient.consultar('usuario', usuario).subscribe(result => {
        this.formUsuario.setValue({
          identificacion: result.identificacion,
          nombre: result.nombre
        })
        this.email = result.email;
        if (result.foto != null) {
          let base64 = "data:" + result.typeImagen + ";base64," + result.foto;
          this.imagen = this.domSanitizer.bypassSecurityTrustUrl(base64);
          this.conservarImagen = true;
        } else {
          this.imagen = 'assets/img/modulos/imagen-icono.png'
        }

      });
    }
  }

  onQuitarImagen() {
    this.imagen = 'assets/img/modulos/imagen-icono.png';
    this.tref.nativeElement.value = '';
    this.fileToUpload = null;
    this.conservarImagen = false;
  }

  handleFileInput(files: FileList) {
    let fileItem = files.item(0);
    this.fileToUpload = fileItem;
    this.conservarImagen = false;
    let reader = new FileReader();

    reader.onload = (event: any) => {
      this.imagen = event.target.result;
    }
    reader.readAsDataURL(fileItem);
  }

  onGuadar() {
    validarTodosCampos(this.formUsuario)
    if (!this.formUsuario.valid) {
      mensajeError('Validar formulario',
        'Todos los campos marcados con <strong class="text-danger text-bold" >(*)</strong> son obligatorios')
    } else {
      const formData: FormData = new FormData();
      if (this.fileToUpload != null)
        formData.append('fileItem', this.fileToUpload, this.fileToUpload.name);
      formData.append('nombre', this.formUsuario.value.nombre)
      formData.append('identificacion', this.formUsuario.value.identificacion)
      formData.append('conservar', this.conservarImagen + '');

      this.restClient.actualizarMultipart('usuario/perfil', this.usuario, formData).subscribe(resp => {
        mensajeInformacion('Registro Actualizado', 'Los datos han sido actualizados satisfactoriamente').then(result => {
          this.postGuardar.emit(resp);
        });
      });
    }
  }
}
