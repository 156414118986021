import { Component, Input, OnInit, TemplateRef } from "@angular/core";
import { Router } from "@angular/router";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { DomSanitizer, SafeUrl } from "@angular/platform-browser";
import { USUARIO, MENU } from "../../needed/const";
import { RestClientService } from "../../needed/services/rest-client.service";
import { AuthService } from "../../needed/services/auth.service";
import { GlobalData } from "../../needed/services/global.data";

@Component({
  selector: "app-dashboard",
  templateUrl: "./default-layout.component.html",
  styleUrls: ["./default-layout.component.css"],
})
export class DefaultLayoutComponent implements OnInit {
  public navItems = [];
  public sidebarMinimized = true;
  private changes: MutationObserver;
  public element: HTMLElement = document.body;
  public razonSocial: string;
  public ruc: string;
  public ambiente: string;

  public dataUsuario: any = {};
  modalRef: BsModalRef;
  public imagenAvatar: SafeUrl;

  nroConvivencia: number = 0;

  constructor(
    private restClientService: RestClientService,
    private authService: AuthService,
    private router: Router,
    private modalService: BsModalService,
    private domSanitizer: DomSanitizer,
    private globalData: GlobalData
  ) {
    this.globalData
      .getConvivencia()
      .subscribe((rs) => (this.nroConvivencia = rs.nroConv));

    this.changes = new MutationObserver((mutations) => {
      this.sidebarMinimized =
        document.body.classList.contains("sidebar-minimized");
    });

    this.changes.observe(<Element>this.element, {
      attributes: true,
    });

    this.dataUsuario = JSON.parse(localStorage.getItem(USUARIO));
    this.cargarImagen();

    if (localStorage.getItem(MENU) != null) {
      this.navItems = JSON.parse(localStorage.getItem(MENU));
    } else {
      this.restClientService
        .listar("navitem/menu", new Map<string, string>())
        .subscribe((result) => {
          localStorage.setItem(MENU, JSON.stringify(result));
          this.navItems = result;
        });
    }
    this.cargarDatos();
  }

  private cargarDatos() {
    let usuario = JSON.parse(localStorage.getItem(USUARIO));
    this.restClientService
      .consultar("usuarioEmisor/usuario", usuario.id)
      .subscribe((result) => {
        if (result != null) {
          this.razonSocial = result.nombremisor;
          this.ruc = result.ruc;
          this.ambiente = result.ambiente;

          this.restClientService
            .consultar("auth/convivencia/cantidad", result.codigoEmisor)
            .subscribe((rs) => {
              this.globalData.sendConvivencia(rs);
            });
        } else {
          this.restClientService
            .consultar("convivencia/cantidad", null)
            .subscribe((rs) => {
              this.globalData.sendConvivencia(rs);
            });
        }
      });
  }

  cargarImagen() {
    if (this.dataUsuario.foto != null) {
      this.imagenAvatar = this.domSanitizer.bypassSecurityTrustUrl(
        "data:" +
        this.dataUsuario.tipoImagen +
        ";base64," +
        this.dataUsuario.foto
      );
    } else {
      this.imagenAvatar = "assets/img/avatars/avatar1.jpg";
    }
  }

  onLogout() {
    this.authService.logout();
    localStorage.removeItem(MENU);
    this.router.navigate(["/login"]);
  }

  ngOnInit() { }

  abrirModulo(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(
      template,
      Object.assign(
        {},
        { class: "modal-dialog-centered" },
        {
          animated: true,
        }
      )
    );
  }

  postGuardarPerfil(data) {
    this.dataUsuario.informacion = data.informacion;
    this.dataUsuario.foto = data.foto;
    this.dataUsuario.tipoImagen = data.tipoImagen;
    this.cargarImagen();
    localStorage.setItem(USUARIO, JSON.stringify(this.dataUsuario));
    this.modalRef.hide();
  }

  abrirConfiguracion() {
    this.router.navigate(["/gts/emisor-configuracion"]);
  }

  isAdmin() {
    return this.dataUsuario.roles.indexOf('Admin') != -1;
  }
}
