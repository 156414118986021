import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,
} from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { RestClientService } from "../../needed/services/rest-client.service";
import {
  validarTodosCampos,
  mensajeError,
  mensajeInformacion,
} from "../../needed/util";
import { NgbTabset } from "@ng-bootstrap/ng-bootstrap";
import { Router } from "@angular/router";

@Component({
  selector: "app-emisores-form-p12",
  templateUrl: "./emisores-form-p12.component.html",
  styleUrls: ["./emisores-form-p12.component.scss"],
})
export class EmisoresFormP12Component implements OnInit {
  @Input() botonContinuar: boolean = true;

  formCertificado: FormGroup;
  @ViewChild("adjuntarLogo") adjuntarLogo: ElementRef;
  @ViewChild("ajuntarCertificado")
  ajuntarCertificado: ElementRef;
  @ViewChild("tabSetMan") tabSetMan: NgbTabset;

  @Input() titulo: string = "Certificado de Firma electronica (P12)";
  @Output() regresar = new EventEmitter<any>();
  @Output() guardar = new EventEmitter<any>();

  certificado: File = null;
  emisor: any;
  usuarioSess: any = { admin: false };
  isGuardar = false;

  codigoEmisor: number = null;
  codigoHashEmisor: string = "";
  logoEmisor: string = "assets/img/modulos/imagen-icono.png";
  cargaImagenNueva = false;

  constructor(
    private fb: FormBuilder,
    private restClientService: RestClientService,
    private router: Router
  ) {
    this.inicializar();
  }

  ngOnInit() {}

  private inicializar() {
    this.formCertificado = this.fb.group({
      fechaValidez: [],
      diasValidos: [],
      password: [
        "",
        Validators.compose([Validators.required, Validators.maxLength(70)]),
      ],
    });
  }

  @Input()
  public set objeto(obj: any) {
    this.codigoHashEmisor = '';
    this.emisor = obj;
    if (obj != null) {
      this.codigoEmisor = obj.codigo;
      this.codigoHashEmisor = obj.codigoEmisor;
      let hoy: Date = new Date();
      let fechaValidez: Date = new Date(obj.fechaExpiraCertificado);
      this.formCertificado.patchValue({
        fechaValidez: obj.fechaExpiraCertificado,
        diasValidos:
          fechaValidez.getTime() == 0
            ? ""
            : Math.round((fechaValidez.getTime() - hoy.getTime()) / 86400000),
        password: obj.claveP12,
      });
    }
  }

  @Input()
  public set usuarioSesion(usss: any) {
    this.usuarioSess = usss;
  }

  onRegresar() {
    this.regresar.emit(this.emisor);
  }

  onGuardar() {
    validarTodosCampos(this.formCertificado);
    if (!this.formCertificado.valid) {
      mensajeError(
        "Validar formulario",
        "Se deben validar los campos obligatorios en el formulario"
      );
    } else {
      if (
        this.certificado == null ||
        this.formCertificado.value.password == ""
      ) {
        mensajeError(
          "Validar la informacion de certificados",
          "Para guardar un certificado debe seleccionar un archivo P12 y diligenciar el password"
        );
      } else {

        if (this.certificado != null) {
          const formData: FormData = new FormData();
          formData.append("certificado", this.certificado, this.certificado.name);
          formData.append("clave", this.formCertificado.value.password);
          this.restClientService
            .guardarMultipart("certificado", formData)
            .subscribe((result) => {
              if (result.errorApp) {
                mensajeInformacion(
                  "Certificado P12",
                  result.errorApp ==
                    "KeyStoreException: No se puede generar KeyStore PKCS12 keystore password was incorrect"
                    ? "Contraseña incorrecta"
                    : result.errorApp
                ).then((res) => {
                  this.isGuardar = false;
                  this.formCertificado.patchValue({
                    fechaValidez: "",
                    diasValidos: "",
                  });
                });
              } else {
                this.isGuardar = true;
                this.formCertificado.patchValue({
                  fechaValidez: result.fechaValidez,
                  diasValidos: result.diasValidos,
                });

                const formData: FormData = new FormData();
                formData.append("certificado", this.certificado, this.certificado.name);
                formData.append("certificadoInfo", this.certificado.name);
                formData.append("password", this.formCertificado.value.password);

                this.restClientService
                  .actualizarMultipart(
                    "emisor/certificado",
                    this.codigoEmisor,
                    formData
                  )
                  .subscribe((result) => {
                    if (result == -1) {
                      mensajeInformacion(
                        "Certificado No Actualizado",
                        "La clave del certificado es incorrecta, porfavor revisar"
                      );
                    } else {
                      mensajeInformacion(
                        "Certificado Actualizado",
                        "El certificado se ha actualizado satisfactoriamente"
                      ).then((res) => {
                        this.emisor = true;
                        this.guardar.emit(result);
                      });
                    }
                  });

              }
            });
        }
      }
    }
  }

  onKeyUp(): void {
      this.isGuardar = this.certificado != null && this.formCertificado.value.password != null && this.formCertificado.value.password.trim() != "";
  }

  handleFileCertificado(evt) {
    let files;
    let fileItem;
    if (evt == null) {
      fileItem = this.certificado;
    } else {
      files = evt.target.files;
      fileItem = files.item(0);
    }
    this.certificado = fileItem;
    this.ajuntarCertificado.nativeElement.innerText = this.certificado.name;
    if (this.certificado != null) {
      const formData: FormData = new FormData();
      formData.append("certificado", this.certificado, this.certificado.name);
      formData.append("clave", this.formCertificado.value.password);
      this.restClientService
        .guardarMultipart("certificado", formData)
        .subscribe((result) => {
          if (result.errorApp) {
            mensajeInformacion(
              "Certificado P12",
              result.errorApp ==
                "KeyStoreException: No se puede generar KeyStore PKCS12 keystore password was incorrect"
                ? "Contraseña incorrecta"
                : result.errorApp
            ).then((res) => {
              this.isGuardar = false;
              this.formCertificado.patchValue({
                fechaValidez: "",
                diasValidos: "",
              });
            });
          } else {
            this.isGuardar = true;
            this.formCertificado.patchValue({
              fechaValidez: result.fechaValidez,
              diasValidos: result.diasValidos,
            });
          }
        });
    }
  }

  continuarSinP12(): void {
    this.guardar.emit({}); // aqui ir al dashboard
  }

  SolicitarP12(): void {
    this.router.navigate(["/solicitarcertificado"]);
  }

  onInico() {
    this.router.navigate(["/dashboard"]);
  }
}
