import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { DataPage } from '../../needed/entities/data-page';

@Component({
  selector: 'app-paginador',
  templateUrl: './paginador.component.html',
  styleUrls: ['./paginador.component.css']
})
export class PaginadorComponent implements OnInit {

  data: DataPage;

  @Output() verDatos = new EventEmitter<boolean>();
  @Output() irPagina = new EventEmitter<number>();

  constructor() { }

  ngOnInit() {
  }

  @Input()
  public set dataPage(data: DataPage){
    this.data = data;
  }

  public get dataPage(): DataPage{
    if(this.data != null){
      return this.data;
    }else{
      return new DataPage();
    }
  }

  public onVerDatos(grilla: boolean){
    this.verDatos.emit(grilla);
  }

  public onSiguiente(){
    if(this.dataPage.pages >= this.dataPage.pageNum + 1){
      this.irPagina.emit(this.dataPage.pageNum + 1)
    }
  }

  public onAtras(){
    if(this.dataPage.pageNum - 1 > 0){
      this.irPagina.emit(this.dataPage.pageNum - 1)
    }
  }

}
