import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { RestClientService } from '../../needed/services/rest-client.service';
import { Router } from '@angular/router';
import { validarTodosCampos, mensajeError, mensajeInformacion } from '../../needed/util';
import { NgbTabset } from '@ng-bootstrap/ng-bootstrap';
import { USUARIO } from '../../needed/const';
import { ModalComponent } from '../../owner/modal/modal.component';

@Component({
  selector: 'app-usuarioemisor',
  templateUrl: './usuarioemisor.component.html',
  styleUrls: ['./usuarioemisor.component.scss']
})
export class UsuarioemisorComponent implements OnInit {

  @ViewChild('modalComp') modalComp: ModalComponent;

  formRegistro: FormGroup;
  @ViewChild('tabSetMan') tabSetMan: NgbTabset;
  emisor: boolean = false;
  codigoEmisor: number = null;
  logoEmisor: any;

  provincias: any[] = []
  ciudades: any[] = []
  logoData: File = null;

  certificado: File = null;
  modeloCertificado: any = {
    fechaValidez: '',
    diasValidos: '',
    password: ''
  }

  constructor(private fb: FormBuilder, private restClientService: RestClientService,
    private router: Router) {
    this.inicializar()
    this.cargarDatos()
    this.cargarInformacionEmisor()
  }

  ngOnInit() {
  }

  cargarDatos() {
    this.restClientService.listar('provincia/listar', new Map<string, any>().set('activo', true)).subscribe(result => {
      this.provincias = result
    })
  }

  cargarInformacionEmisor() {
    let usuario = JSON.parse(localStorage.getItem(USUARIO));

    this.restClientService.consultar('usuarioEmisor/usuario', usuario.id).subscribe(result => {
      if (result != null) {
        this.codigoEmisor = result.codigoEmisor;
        this.restClientService.consultar('emisor', result.codigoEmisor).subscribe(emisores => {
          this.seleccionarProvincia(emisores.codigoProvincia);
          this.formRegistro.patchValue({
            ruc: emisores.ruc,
            razonSocial: emisores.razonSocial,
            nombreComercial: emisores.nombreComercial,
            provincia: emisores.codigoProvincia,
            ciudad: emisores.codigoCiudad,
            direccion: emisores.direccion,
            telefono: emisores.telefono,
            email: emisores.email,
            llevaContabilidad: emisores.llevaContabilidad,
            exportador: emisores.exportador,
            contribuyente: emisores.contribuyenteEspecial,
            resolucion: emisores.resolucion
          })
          this.emisor = true
        });
        this.restClientService.descargarArchivo('emisor/logo', result.codigoEmisor).subscribe(resLogo => {
          if (resLogo != null) {
            this.logoData = this.convertirBlobFile(resLogo, 'archivo.png');
            let reader = new FileReader();
            reader.onload = (event: any) => {
              this.logoEmisor = event.target.result;
            }
            reader.readAsDataURL(resLogo)
          }
        })
      }
    })

    this.formRegistro.patchValue({
      email: usuario.email
    })
  }

  cargarInformacionCertificado() {
    this.restClientService.consultar('certificado', this.codigoEmisor).subscribe(cert => {
      this.modeloCertificado.fechaValidez = cert.fechaValidez;
      this.modeloCertificado.diasValidos = cert.diasValidos;
      this.modeloCertificado.password = cert.password;
    });
    this.restClientService.descargarArchivo('certificado/certificadop12', this.codigoEmisor).subscribe(resP12 => {
      if (resP12 != null) {
        this.certificado = this.convertirBlobFile(resP12, 'archivo.p12');
      }
    })
  }

  convertirBlobFile(blob: Blob, fileName: string) {
    let b: any = blob;
    b.lastModifiedDate = new Date();
    b.name = fileName;
    return <File>blob;
  }

  private inicializar() {
    this.formRegistro = this.fb.group({
      ruc: ['', Validators.compose([Validators.required])],
      razonSocial: ['', Validators.compose([Validators.required
        , Validators.maxLength(70)])],
      nombreComercial: ['', Validators.required],
      provincia: [null, Validators.required],
      ciudad: [null, Validators.required],
      direccion: ['', Validators.required],
      telefono: ['', Validators.required],
      email: ['', Validators.compose([Validators.required, Validators.email])],
      llevaContabilidad: [false],
      exportador: [false],
      contribuyente: [''],
      resolucion: ['']
    })
  }

  guardar() {
    validarTodosCampos(this.formRegistro);
    if (!this.formRegistro.valid) {
      mensajeError('Validar formulario', 'Se deben validar los campos obligatorios en el formulario')
    } else {
      const formData: FormData = new FormData();
      formData.append('ruc', this.formRegistro.value.ruc);
      formData.append('razonSocial', this.formRegistro.value.razonSocial);
      formData.append('nombreComercial', this.formRegistro.value.nombreComercial);
      formData.append('codigoProvincia', this.formRegistro.value.provincia);
      formData.append('codigoCiudad', this.formRegistro.value.ciudad);
      formData.append('direccion', this.formRegistro.value.direccion);
      formData.append('telefono', this.formRegistro.value.telefono);
      formData.append('email', this.formRegistro.value.email);
      formData.append('exportador', this.formRegistro.value.exportador);
      formData.append('contribuyenteEspecial', this.formRegistro.value.contribuyente);
      formData.append('resolucion', this.formRegistro.value.resolucion);

      if (this.logoData != null) {
        formData.append('logoInfo', this.logoData.name);
        formData.append('logoData', this.logoData, this.logoData.name);
      }

      if (this.codigoEmisor != null) {
        this.modalComp.showAction()
        this.restClientService.actualizarMultipart('emisor', this.codigoEmisor, formData, () => { this.modalComp.hideAction() }).subscribe(result => {
          mensajeInformacion('Emisor Actualizado', 'El emisor se ha actualizado satisfactoriamente').then(res => {
            this.emisor = true
            setTimeout(() => { this.tabSetMan.select('certificado');  this.modalComp.hideAction() }, 500)
          })
        })
      } else {
        this.modalComp.showAction()
        this.restClientService.guardarMultipart('emisor', formData, () => { this.modalComp.hideAction() }).subscribe(result => {
          this.codigoEmisor = result.codigo;
          mensajeInformacion('Emisor Registrado', 'El emisor se ha registrado satisfactoriamente').then(res => {
            this.emisor = true
            setTimeout(() => { this.tabSetMan.select('certificado'); this.modalComp.hideAction() }, 500)
          })
        })
      }
    }
  }

  seleccionarProvincia(prov: any) {
    if (prov != null) {
      this.restClientService.listar('ciudad/listar', new Map<string, any>().set('codigoProvincia', prov)).subscribe(result => {
        this.ciudades = result;
      })
    } else {
      this.formRegistro.patchValue({ ciudad: null })
      this.ciudades = []
    }
  }

  continuar() {
    setTimeout(() => { this.tabSetMan.select('certificado') }, 500)
  }

  handleFileInput(evt) {
    let files = evt.target.files
    let fileItem = files.item(0);
    // console.log("file input has changed. The file is", fileItem)
    this.logoData = fileItem;
    let reader = new FileReader();

    reader.onload = (event: any) => {
      this.logoEmisor = event.target.result;
    }
    if (fileItem != null) {
      reader.readAsDataURL(fileItem);
    } else {
      this.logoEmisor = ''
    }

  }

  handleFileCertificado(evt) {
    let files = evt.target.files
    let fileItem = files.item(0);
    // console.log("file input has changed. The file is", fileItem)
    this.certificado = fileItem;
    if (this.certificado != null) {
      const formData: FormData = new FormData();
      formData.append('certificado', this.certificado, this.certificado.name);
      this.restClientService.guardarMultipart('certificado', formData).subscribe(result => {
        // console.log(result)
        this.modeloCertificado.fechaValidez = result.fechaValidez;
        this.modeloCertificado.diasValidos = result.diasValidos;
      })
    }
  }

  guardarCertificado() {
    if (this.certificado == null || this.modeloCertificado.password == '') {
      mensajeError('Validar la informacion de certificados', 'Para guardar un certificado debe seleccionar un archivo p12 y diligenciar el password');
    } else {
      const formData: FormData = new FormData();
      formData.append('certificado', this.certificado, this.certificado.name);
      formData.append('certificadoInfo', this.certificado.name);
      formData.append('password', this.modeloCertificado.password);

      this.restClientService.actualizarMultipart('emisor/certificado', this.codigoEmisor, formData).subscribe(result => {
        mensajeInformacion('Certificado Actualizado', 'El certificado se ha actualizado satisfactoriamente').then(res => {
          this.router.navigate(['/dashboard'])
        })
      })
    }
  }

  beforeChange(evento){
    if(evento.nextId == 'certificado'){
      this.cargarInformacionCertificado()
    }
  }
}
