import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { URL_SERVICE } from '../const';
import { DataPage } from '../entities/data-page';
import { catchError } from 'rxjs/operators';
import { mensajeError } from '../util';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class RestClientService {

  constructor(private http: HttpClient, private router: Router) {
  }

  public guardarMultipart(path: string, body: any, accion?): Observable<any> {
    const headers = new HttpHeaders();
    return this.http.post(URL_SERVICE + path, body, { headers: headers }).pipe(
      catchError(error => {
        this.verificar(error, accion);
        return of();
      })
    );
  }

  public actualizarMultipart(path: string, id: any, body: any, accion?): Observable<any> {
    const headers = new HttpHeaders();
    return this.http.put(URL_SERVICE + path + '/' + id, body, { headers: headers }).pipe(
      catchError(error => {
        this.verificar(error, accion);
        return of();
      })
    );
  }

  public guardar(path: string, body: any, accion?): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.post(URL_SERVICE + path, body, { headers: headers }).pipe(
      catchError(error => {
        this.verificar(error, accion);
        return of();
      })
    );
  }

  public guardarPost(path: string, accion?): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.post(URL_SERVICE + path, { headers: headers }).pipe(
      catchError(error => {
        this.verificar(error, accion);
        return of();
      })
    );
  }

  public actualizar(path: string, id: any, body: any, accion?): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.put(URL_SERVICE + path + '/' + id, body, { headers: headers }).pipe(
      catchError(error => {
        this.verificar(error, accion);
        return of();
      })
    );
  }

  public consultar(path: string, id: any, accion?): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    let url = URL_SERVICE + path;
    if (id != null) {
      url = url + '/' + id;
    }
    return this.http.get(url, { headers: headers }).pipe(
      catchError(error => {
        this.verificar(error, accion);
        return of();
      })
    );
  }

  public eliminar(path: string, id: number, accion?) {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.delete(URL_SERVICE + path + '/' + id, { headers: headers }).pipe(
      catchError(error => {
        this.verificar(error, accion);
        return of();
      })
    );
  }

  public listar(path: string, parametros?: Map<string, any>, accion?): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    let params = new HttpParams();
    if (parametros == null) {
      parametros = new Map<string, any>();
    }
    parametros.forEach((value: any, key: string) => {
      if (value instanceof Array) {
        params = params.set(key, value.join(', '));
      } else {
        params = params.set(key, value);
      }
    });
    return this.http.get(URL_SERVICE + path, { params: params, headers: headers }).pipe(
      catchError(error => {
        this.verificar(error, accion);
        return of();
      })
    );
  }

  public paginar(path: string, pagina: number, parametros: Map<string, any>, accion?): Observable<DataPage> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    let params = new HttpParams().set('pagina', pagina + '');
    parametros.forEach((value: any, key: string) => {
      if (value instanceof Array) {
        params = params.set(key, value.join(', '));
      } else {
        params = params.set(key, value);
      }
    });
    return this.http.get<DataPage>(URL_SERVICE + path, { params: params, headers: headers }).pipe<DataPage>(
      catchError(error => {
        this.verificar(error, accion);
        return throwError(error);
      })
    );
  }

  public paginarLista(path: string, pagina: number, parametros: Map<string, any>, accion?): Observable<any[]> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    let params = new HttpParams().set('pagina', pagina + '');
    parametros.forEach((value: any, key: string) => {
      if (value instanceof Array) {
        params = params.set(key, value.join(', '));
      } else {
        params = params.set(key, value);
      }
    });
    return this.http.get<any[]>(URL_SERVICE + path, { params: params, headers: headers }).pipe<any[]>(
      catchError(error => {
        this.verificar(error, accion);
        return throwError(error);
      })
    );
  }

  public descargarArchivo(path: string, id?: any, accion?): Observable<Blob> {
    if (id != null) {
      return this.http.get(URL_SERVICE + path + '/' + id, { responseType: 'blob' }).pipe<Blob>(
        catchError(error => {
          this.verificar(error, accion);
          return throwError(error);
        })
      );
    } else {
      return this.http.get(URL_SERVICE + path, { responseType: 'blob' }).pipe<Blob>(
        catchError(error => {
          this.verificar(error, accion);
          return throwError(error);
        })
      );
    }
  }

  public descargarArchivoPublico(path: string, id?: any, accion?) {
    const headers = new HttpHeaders().set('X-Api-Public', 'true');
    return this.http.get(URL_SERVICE + path + '/' + id, { headers: headers, responseType: 'blob', observe: 'response' });
  }

  public descargarArchivoParam(path: string, parametros: Map<string, any>, accion?): Observable<Blob> {
    let params = new HttpParams();
    parametros.forEach((value: any, key: string) => {
      if (value instanceof Array) {
        params = params.set(key, value.join(', '));
      } else {
        params = params.set(key, value);
      }
    });
    return this.http.get(URL_SERVICE + path, { params: params, responseType: 'blob' }).pipe<Blob>(
      catchError(error => {
        this.verificar(error, accion);
        return throwError(error);
      })
    );
  }

  private verificar(error, accion) {
    // console.error('Error desde el interceptor REST ', error);
    setTimeout(() => {
      if (error.status === 0) {
        mensajeError('Error de comunicacion', 'El sistema ha reportado un error al conectarse con el servidor').then(result => {
          if (accion != null) {
            accion();
          }
          this.router.navigate(['/404']);
        });
      } else if (error.status === 400) {
        mensajeError('Error en la petición', 'El sistema ha reportado problemas en la petición').then(a => {
          if (accion != null) {
            accion();
          }
        });
      } else if (error.status === 401) {
        mensajeError('Error de autenticacion',
          'El sistema ha reportado problemas al tratar de autenticarse. Revise sus credenciales').then(a => {
            if (accion != null) {
              accion();
            }
          });
      } else if (error.status === 404) {
        mensajeError('Error  de comunicacion',
          'El sistema ha reportado problemas al tratar de conectarse al sitio. No lo ha encontrado').then(a => {
            if (accion != null) {
              accion();
            }
          });
      } else if (error.status === 500) {
        mensajeError('Error de servidor',
          'El sistema ha reportado un error en el servidor. <br><strong>' + error.error.errorApp + '</strong>').then(a => {
            if (accion != null) {
              accion();
            }
          });
      } else if (error.status === 403) {
        mensajeError('Error de acceso',
          'Actuamente no tiene acceso a esta direccion. Verificar si ha accedido correctamente a la APP').then(a => {
            if (accion != null) {
              accion();
            }
            this.router.navigate(['/login']);
          });
      }
    }, 1000);

  }

  public desactivar(path: string, id: any, body: any, accion?): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.post(URL_SERVICE + path + '/' + id, body, { headers: headers }).pipe(
      catchError(error => {
        this.verificar(error, accion);
        return of();
      })
    );
  }

  public activar(path: string, id: any, body: any, accion?): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.post(URL_SERVICE + path + '/' + id, body, { headers: headers }).pipe(
      catchError(error => {
        this.verificar(error, accion);
        return of();
      })
    );
  }

}
