import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { LocationStrategy, HashLocationStrategy } from '@angular/common';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppAsideModule } from '@coreui/angular';
import { AppBreadcrumbModule } from '@coreui/angular';
import { AppFooterModule } from '@coreui/angular';
import { AppHeaderModule } from '@coreui/angular';
import { AppSidebarModule } from '@coreui/angular';

// Import containers
import { DefaultLayoutComponent } from './containers';

import { P404Component } from './views/error/404.component';
import { P500Component } from './views/error/500.component';
import { LoginComponent } from './views/login/login.component';

const APP_CONTAINERS = [
  DefaultLayoutComponent
];



// Import routing module
import { AppRoutingModule } from './app.routing';

// Import 3rd party components
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { NgChartsModule } from 'ng2-charts';
import { RestClientService } from './needed/services/rest-client.service';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgSelectModule } from '@ng-select/ng-select';
import { AuthGuard } from './needed/guard/auth.guard';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RestInterceptor } from './needed/interceptor/rest-interceptor';
import { PerfilComponent } from './views/perfil/perfil.component';
import { ModalModule } from 'ngx-bootstrap/modal';
import { UsuarioemisorComponent } from './views/usuarioemisor/usuarioemisor.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from './needed/services/auth.service';
import { ConfigModule } from './config/config.module';
import { RecuperarComponent } from './views/recuperar/recuperar.component';
import { OwnerModule } from './owner/owner.module';
import { GlobalData } from './needed/services/global.data';
import { RegisterComponent } from './views/register/register.component';
import { UsuariocertificadoComponent } from './views/usuariocertificado/usuariocertificado.component';
import { SolicitarCertificadoComponent } from './config/solicitar-certificado/solicitar-certificado.component';
import { RecuperarUsuarioComponent } from './views/recuperar-usuario/recuperar-usuario.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

@NgModule({
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    OwnerModule,
    AppAsideModule,
    AppBreadcrumbModule.forRoot(),
    AppFooterModule,
    ConfigModule,
    AppHeaderModule,
    AppSidebarModule,
    PerfectScrollbarModule,
    BsDropdownModule.forRoot(),
    TabsModule.forRoot(),
    NgChartsModule,
    HttpClientModule,
    ModalModule.forRoot(),
    NgSelectModule,
    NgbModule,
    BrowserAnimationsModule,
    FontAwesomeModule
  ],
  declarations: [
    AppComponent,
    P404Component,
    P500Component,
    LoginComponent,
    RecuperarComponent,
    PerfilComponent,
    UsuarioemisorComponent,
    RegisterComponent,
    DefaultLayoutComponent,
    UsuariocertificadoComponent,
    SolicitarCertificadoComponent,
    RecuperarUsuarioComponent
  ],
  providers: [
    GlobalData,
    AuthGuard,
    AuthService,
    RestClientService,
    {
      provide: LocationStrategy,
      useClass: HashLocationStrategy
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RestInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
