import { Injectable } from "@angular/core";
import { Subject, Observable } from "rxjs";

@Injectable()
export class GlobalData{

    private subject = new Subject<any>();

    sendConvivencia(nroConv: string) {
        this.subject.next({ nroConv: nroConv });
    }

    getConvivencia(): Observable<any> {
        return this.subject.asObservable();
    }

}