import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EmisorComponent } from './emisor/emisor.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ModalModule } from 'ngx-bootstrap/modal';

import { EmisoresFormComponent } from './emisores-form/emisores-form.component';
import { EmisoresFormP12Component } from './emisores-form-p12/emisores-form-p12.component';
import { ResetearclaveComponent } from './resetearclave/resetearclave.component';
import { EmisorCertificadoComponent } from './emisor-certificado/emisor-certificado.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    NgSelectModule,
    ReactiveFormsModule,
    NgbModule,
    ModalModule.forRoot()
  ],
  declarations: [
    EmisorComponent,
    EmisoresFormComponent,
    EmisoresFormP12Component,
    ResetearclaveComponent,
    EmisorCertificadoComponent
  ],
  exports: [
    EmisorComponent,
    EmisorCertificadoComponent
  ]
})
export class ConfigModule { }
