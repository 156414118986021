import { Component, Input, Output, OnInit, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-dropdown-contextual',
  templateUrl: './dropdown-contextual.component.html',
  styleUrls: ['./dropdown-contextual.component.scss']
})
export class DropdownContextualComponent implements OnInit {

  @Output() verRegistro = new EventEmitter<any>();
  @Output() editarRegistro = new EventEmitter<any>();
  @Output() enviarFactura = new EventEmitter<any>();
  @Output() reenviarCorreo = new EventEmitter<any>();
  @Output() autorizarFactura = new EventEmitter<any>();
  @Output() eliminarRegistro = new EventEmitter<any>();

  @Input()
  registro: any;

  @Input()
  plantilla: boolean;

  @Input()
  idModalReenviar: string;

  public visible: boolean = false;
  public isOver: boolean = false;

  constructor() { }

  ngOnInit(): void {
    // console.log(this.registro);
  }

  setVisibility(status: any) {
    // console.log(status);

    if (status == false) {
      setTimeout(() => {
        this.visible = status;
      }, 1000);
    } else {
      this.visible = status;
    }
  }

  public onView() {
    this.verRegistro.emit(this.registro);
  }

  public onEdit() {
    this.editarRegistro.emit(this.registro);
  }

  public onSend() {
    this.enviarFactura.emit(this.registro);
  }

  public onResend() {
    this.reenviarCorreo.emit(this.registro);
  }

  public onAuthorize() {
    this.autorizarFactura.emit(this.registro);
  }

  public onDelete() {
    this.eliminarRegistro.emit(this.registro);
  }

}
