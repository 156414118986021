import { Component, OnInit } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { PasswordValidation } from "../../needed/interceptor/password-validation";
import {
  validarTodosCampos,
  mensajeError,
  mensajeInformacion,
} from "../../needed/util";
import { RestClientService } from "../../needed/services/rest-client.service";
import { Router } from "@angular/router";
import { AuthService } from "../../needed/services/auth.service";
import { USUARIO } from "../../needed/const";

@Component({
  selector: "app-resetearclave",
  templateUrl: "./resetearclave.component.html",
  styleUrls: ["./resetearclave.component.scss"],
})
export class ResetearclaveComponent implements OnInit {
  formRegistro: FormGroup;
  invalid = true;

  constructor(
    private fb: FormBuilder,
    private restClientService: RestClientService,
    private router: Router,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    this.formRegistro = this.fb.group(
      {
        password: ["", null],
        repassword: ["", null],
      },
      {
        validator: PasswordValidation.MatchPassword,
      }
    );
  }

  validar(event: any) {
    this.invalid = false;
    if (!this.isContrasena(this.formRegistro.value.password)) {
      this.formRegistro.controls.password.setErrors({ incorrect: true });
      this.invalid = true;
    } else {
      this.formRegistro.controls.password.setErrors(null);
    }
    if (
      !this.isRecontrasena(
        this.formRegistro.value.password,
        this.formRegistro.value.repassword
      )
    ) {
      this.formRegistro.controls.repassword.setErrors({ incorrect: true });
      this.invalid = true;
    } else {
      this.formRegistro.controls.repassword.setErrors(null);
    }
  }

  registrar() {
    if (this.invalid) {
      mensajeError(
        "Validar contraseña",
        "Se deben validar los campos obligatorios en el formulario"
      );
      return;
    }
    let usuario = JSON.parse(localStorage.getItem(USUARIO));
    const body = {
      username: usuario.usuario,
      password: this.formRegistro.value.password,
    };
    this.restClientService
      .guardar("auth/restablecer", body)
      .subscribe((result) => {
        mensajeInformacion(
          "Nueva contraseña",
          "La contraseña nueva se estableció satisfactoriamente"
        ).then((res) => {
          this.cerrarSesion();
        });
      });
  }

  isContrasena(valor: string) {
    try {
      return valor.trim().length >= 8;
    } catch (err) {
      return false;
    }
  }

  isRecontrasena(valor: string, valor2: string) {
    try {
      return valor2.trim().length >= 8 && valor2 == valor;
    } catch (err) {
      return false;
    }
  }

  cerrarSesion() {
    this.authService.logout();
    this.router.navigate(["/login"]);
  }
}
