import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PaginadorComponent } from './paginador/paginador.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ModalModule } from 'ngx-bootstrap/modal';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { ModalComponent } from './modal/modal.component';
import { VisorDocumentoComponent } from './visor-documento/visor-documento.component';
import { DropdownContextualComponent } from './dropdown-contextual/dropdown-contextual.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    NgSelectModule,
    ReactiveFormsModule,
    NgbModule,
    ModalModule.forRoot()
  ],
  exports: [PaginadorComponent, ModalComponent, VisorDocumentoComponent, DropdownContextualComponent],
  declarations: [PaginadorComponent, ModalComponent, VisorDocumentoComponent, DropdownContextualComponent]
})
export class OwnerModule { }
